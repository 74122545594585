<template>
  <v-container fluid class="px-0">
    <GoalsCardSetting />
  </v-container>
</template>

<script>
import GoalsCardSetting from "@/components/layout/goals/settings/GoalsCardSetting";
export default {
  name: "GoalsSetting",
  components: { GoalsCardSetting }
};
</script>

<style scoped></style>
