<template>
  <v-row>
    <v-col cols="12">
      <div class="font-weight-medium main--text lighten-2 text-heading-2">
        {{ $t("goal_set_first_option_first_step") }}
      </div>
      <InputCurrency
        class="annual-sales--input"
        color="secondary"
        v-model.number="value"
        :height="64"
        @input-changed="$emit('goal-changed')"
        @input-lose-focus="$emit('goal-done-editing')"
      >
      </InputCurrency>
    </v-col>

    <template v-if="value !== 0">
      <v-col cols="12" class="text-center py-0">
        <v-icon color="#C4C4C4">
          mdi-menu-down
        </v-icon>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import InputCurrency from "@/components/ui/InputCurrency";

export default {
  name: "GoalsCardSettingOptionOne",
  components: { InputCurrency },
  props: {
    annualInputValue: {
      type: Number,
      default: 0,
      required: true
    }
  },
  computed: {
    value: {
      get: function() {
        return this.annualInputValue;
      },
      set: function(newValue) {
        return this.$emit("update:annual-input-value", newValue);
      }
    }
  },
  data() {
    return {
      modalTitle: "Help",
      modalBody:
        "At the time of initial input, the following conditions are applied based on the annual target, and the reference value is automatically output.\n" +
        "・ March, April → 20% up\n" +
        "・ December → 30% up\n" +
        "・ February, August → 20% down"
    };
  },
  methods: {
    showModal() {
      this.$store.commit("showModal", {
        title: this.modalTitle,
        body: this.modalBody
      });
    }
  }
};
</script>

<style scoped>
.annual-sales--input >>> input {
  color: #0094ff !important;
  font-size: 38px;
  padding: 0 !important;
  max-height: 48px !important;
  line-height: 24px !important;
}
</style>
