<template>
  <v-row>
    <v-col cols="12">
      <div class="font-weight-medium main--text lighten-2 text-heading-2">
        {{ $t("goal_set_second_option_first_step") }}
      </div>
    </v-col>

    <v-col cols="12">
      <v-row
        align="center"
        justify="center"
        class="font-weight-medium main--text lighten-2 text-heading-2"
      >
        <div class="text-center d-flex">
          {{ $t("value_is") }}
        </div>
        <v-col cols="auto" class="px-0">
          <v-btn
            @click="toggleNegative"
            text
            plain
            class="text-heading-7 main--text lighten-2"
            x-small
            :ripple="false"
          >
            {{ negativeText }}
          </v-btn>
        </v-col>
        <v-col cols="3" class="pl-0">
          <InputNumber
            class="percentage-target--input"
            v-model.number="value"
            :height="20"
            hide-details
            custom
          />
        </v-col>
        <div class="text-center">
          {{ $("of_last_year") }}
        </div>
      </v-row>
    </v-col>

    <v-col cols="12" class="text-center py-0">
      <v-icon color="#C4C4C4">
        mdi-menu-down
      </v-icon>
    </v-col>

    <v-col cols="12" class="text-center font-weight-medium secondary--text">
      <div class="text-heading-2">
        {{ $t("annual_sales_target_for_this_term") }}
      </div>
      <div class="text-heading-9 mt-4 annual-value-text">
        {{ annualValue | toCurrency }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
import InputNumber from "@/components/ui/InputNumber";
import { mapMutations } from "vuex";
export default {
  name: "GoalsCardSettingOptionTwo",
  components: { InputNumber },
  props: {
    lastYearValue: {
      type: Number,
      default: 0,
      required: true
    },
    annualValue: {
      type: Number,
      default: 0,
      required: false
    },
    annualInputPercentage: {
      type: Number,
      default: 0,
      required: true
    },
    isNegative: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  computed: {
    negativeText() {
      return this.isNegative ? "-" : "+";
    },
    value: {
      get: function() {
        return this.annualInputPercentage;
      },
      set: function(newValue) {
        return this.$emit("update:annual-input-percentage", newValue);
      }
    },
    _isNegative: {
      get: function() {
        return this.isNegative;
      },
      set: function(newValue) {
        return this.$emit("update:is-negative", newValue);
      }
    }
  },
  methods: {
    toggleNegative() {
      this._isNegative = !this._isNegative;
    },
    ...mapMutations(["showModal"])
  }
};
</script>

<style scoped>
.percentage-target--input >>> input {
  font-size: 15px !important;
  font-weight: 500 !important;
  color: #555555;
  padding: 0 !important;
}
.annual-value-text {
  line-height: 1.5 !important;
}
</style>
