<template>
  <v-text-field
    v-model="_value"
    min="0"
    inputmode="numeric"
    type="number"
    :class="custom && 'custom-number-input text-center mt-0'"
    :color="color"
    flat
    :height="height"
    @keypress="isNumberKey($event)"
    @keydown.48="handleZero($event)"
    @keydown.96="handleZero($event)"
    @keydown.delete="handleDelete($event)"
    v-bind="$attrs"
  >
  </v-text-field>
</template>

<script>
import mixins from "@/mixins/index";
export default {
  name: "InputNumber",
  mixins: [mixins],
  props: {
    value: {
      type: Number,
      default: 0,
      required: true
    },
    max: {
      type: Number,
      default: 10000000,
      required: false
    },
    color: {
      type: String,
      default: "primary",
      required: false
    },
    height: {
      type: Number,
      default: 20,
      required: false
    },
    custom: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      isFocused: false
    };
  },
  computed: {
    _value: {
      get: function() {
        return this.value;
      },
      set: function(newValue) {
        this.$emit("input", newValue);
      }
    }
  },
  methods: {
    handleDelete(event) {
      if (this._value === 0) event.preventDefault();
    },
    handleZero(event) {
      if (this._value === 0) event.preventDefault();
    }
  }
};
</script>

<style scoped>
.custom-number-input {
  background: #f5f5f5 !important;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px;
}

.custom-number-input >>> input {
  text-align: center;
  margin-bottom: 8px;
}
.custom-number-input >>> .v-input__slot:before {
  border-style: none !important;
}

.custom-number-input >>> .v-input__slot:after {
  border-style: none !important;
}
</style>
