<template>
  <CardBase
    :title="$t('goal_set_target_title')"
    :show-date="false"
    :help-button="false"
    :loading="loading"
  >
    <template v-slot:content-body>
      <v-row>
        <v-col cols="12">
          <v-card flat color="grey lighten-3" class="rounded-0 text-center">
            <v-card-text>
              <div class="main--text font-weight-medium text-heading-5">
                {{ $t("target_for_this_term") }}
              </div>
              <div class="main--text font-weight-medium text-heading-5">
                {{ goalSetting.currentTerm }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" class="text-center">
          <v-row align="center" justify="center" class="my-4">
            <div>
              <v-avatar size="46" class="reference-value-background">
                <div class="text-heading-1 main--text lighten-2">
                  {{ $t("reference_value") }}
                </div>
              </v-avatar>
            </div>

            <div
              class="font-weight-medium text-center main--text lighten-2 ml-4"
            >
              <div class="text-heading-4 pb-2">
                {{
                  $t("goal_set_sales_result", { term: goalSetting.lastTerm })
                }}
              </div>
              <div
                v-if="goalSetting.lastTermResult !== 0"
                class="text-heading-8"
              >
                {{ goalSetting.lastTermResult | toCurrency }}
              </div>
              <div v-else class="text-heading-5">
                {{ $t("no_data") }}
              </div>
            </div>
          </v-row>
        </v-col>

        <v-col cols="12">
          <v-select
            :items="goalSettingsType"
            v-model="goalSetting.type"
            item-value="type"
            item-text="text"
            dense
            outlined
            solo
            flat
            hide-details
            append-icon="$blueDownArrow"
            class="goal-setting-type--select font-weight-medium main--text text-heading-2"
            @change="isTableShown = false"
          >
          </v-select>
        </v-col>

        <v-col cols="12" class="text-right pt-0">
          <v-btn
            class="main--text lighten-3 font-weight-medium text-heading-1"
            text
            small
            :ripple="false"
            @click="clearInputs"
          >
            {{ $t("clear") }}
            <v-icon size="12" right>
              $reload
            </v-icon>
          </v-btn>
        </v-col>

        <v-col cols="12" v-if="goalSetting.type === 1">
          <GoalsCardSettingOptionOne
            :annual-input-value.sync="goalSetting.annualSalesTargetNewValue"
            @goal-changed="goalValueChange"
            @goal-done-editing="calculateGoalValue()"
          />
        </v-col>

        <v-col cols="12" v-else>
          <GoalsCardSettingOptionTwo
            :last-year-value="goalSetting.lastTermResult"
            :annual-input-percentage.sync="
              goalSetting.annualSalesTargetNewPercentage
            "
            :annual-value="calculatedAnnualValue"
            :is-negative.sync="isNegative"
          />
        </v-col>

        <template v-if="showMonthlyTable()">
          <v-col cols="12">
            <div class="font-weight-medium main--text lighten-2 text-heading-2">
              {{ $t("goal_set_first_option_second_step") }}
            </div>
            <div class="text-right">
              <v-btn
                class="white--text help-fab"
                elevation="0"
                color="grey lighten-1"
                :ripple="false"
                x-small
                rounded
                icon
                @click="
                  showModal({
                    title: '売上目標の設定',
                    component: 'GoalsSettingModal'
                  })
                "
              >
                <v-icon size="14">$question</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12">
            <TableMonthlyTarget
              @monthly-value-change="monthlyValueChange"
              :editable="true"
              :items="goals"
            />
          </v-col>

          <v-col cols="12" class="text-center" @click="updateGoalSetting">
            <v-btn
              depressed
              small
              min-width="100"
              color="secondary"
              class="font-weight-regular text-heading-1 text-none"
              :loading="loading"
            >
              {{ $t("update") }}
            </v-btn>
          </v-col>
        </template>
      </v-row>
    </template>
  </CardBase>
</template>

<script>
import CardBase from "@/components/ui/CardBase";
import TableMonthlyTarget from "@/components/ui/TableMonthlyTarget";
import GoalsCardSettingOptionOne from "@/components/layout/goals/settings/GoalsCardSettingOptionOne";
import GoalsCardSettingOptionTwo from "@/components/layout/goals/settings/GoalsCardSettingOptionTwo";
import { mapMutations, mapGetters } from "vuex";
import mixins from "@/mixins/index";
import dayjs from "dayjs";

export default {
  name: "GoalsCardSetting",
  mixins: [mixins],
  components: {
    GoalsCardSettingOptionTwo,
    GoalsCardSettingOptionOne,
    TableMonthlyTarget,
    CardBase
  },
  created() {
    this.loading = true;
    this.$store
      .dispatch("GOALS_GET")
      .then(() => {
        let start_date = this.getGoalBusinessYear.start_date;
        let end_date = this.getGoalBusinessYear.end_date;

        this.goalSetting.lastTermResult = this.getGoalPreviousTerm.sale;
        this.goalSetting.lastTerm = this.getGoalPreviousTerm.term;

        this.goalSetting.currentTerm = `
          ${this.getGoalBusinessYear.term}期
          ${dayjs(start_date).format("YYYY年M月")}
          ～
          ${dayjs(end_date).format("YYYY年M月")}
          `;

        this.initialCalculation();
      })
      .finally(() => (this.loading = false));
  },
  computed: {
    ...mapGetters(["getGoals", "getGoalPreviousTerm", "getGoalBusinessYear"]),
    date() {
      return new Date().toISOString().split("T")[0];
    },
    calculatedAnnualValue() {
      if (!this.isTableShown) return 0;
      if (!this.isNegative) {
        return (
          this.goalSetting.lastTermResult +
          (this.goalSetting.annualSalesTargetNewPercentage / 100) *
            this.goalSetting.lastTermResult
        );
      } else {
        return (
          this.goalSetting.lastTermResult -
          (this.goalSetting.annualSalesTargetNewPercentage / 100) *
            this.goalSetting.lastTermResult
        );
      }
    }
  },
  data() {
    return {
      loading: false,
      isTableShown: false,
      isNegative: false,
      goalSetting: {
        currentTerm: "",
        lastTerm: "",
        lastTermResult: 0,
        annualSalesTargetCurrent: 0,
        annualSalesTargetNewValue: 0,
        annualSalesTargetNewPercentage: 0,
        type: 1
      },
      goalSettingsType: [
        {
          type: 1,
          text: this.$t("goal_set_first_option")
        },
        {
          type: 2,
          text: this.$t("goal_set_second_option")
        }
      ],
      useDistributionFactor: true,
      distributionFactors: [
        0.0813,
        0.065,
        0.0976,
        0.0976,
        0.0813,
        0.0813,
        0.0813,
        0.065,
        0.0813,
        0.0813,
        0.0813,
        0.105
      ],
      goals: []
    };
  },

  watch: {
    "goalSetting.annualSalesTargetInput": function(newValue) {
      if (newValue === "") this.goalSetting.annualSalesTargetNewValue = 0;
    },
    "goalSetting.annualSalesTargetNewPercentage": function(newValue) {
      this.isTableShown = true;
      if (newValue === "") this.goalSetting.annualSalesTargetNewPercentage = 0;
    }
  },

  methods: {
    clearInputs() {
      this.goalSetting.annualSalesTargetNewValue = 0;
      this.goalSetting.annualSalesTargetNewPercentage = 0;
      this.$nextTick(() => {
        this.isTableShown = false;
      });
    },
    updateGoalSetting() {
      this.loading = true;

      let goals = this.goals.map(goal => {
        return {
          month: goal.id,
          goal: goal.target_monthly_sale
        };
      });

      this.$store
        .dispatch("GOALS_UPDATE", { goals: goals })
        .then(() => {
          this.$router.push("/goals");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showMonthlyTable() {
      if (this.goalSetting.type === 1) {
        return this.goalSetting.annualSalesTargetNewValue >= 1000;
      } else if (this.goalSetting.type === 2) {
        return (
          this.goalSetting.annualSalesTargetNewPercentage !== 0 ||
          this.isTableShown
        );
      }
      return true;
    },
    calculateMonthlyValue() {
      let goalValue = this.goalSetting.annualSalesTargetNewValue;
      let monthlyValues = [];
      for (const [index, goal] of this.getGoals.entries()) {
        let distributionFactor = this.distributionFactors[index];

        monthlyValues.push({
          id: goal.month.id,
          month: index + 1,
          target_monthly_sale: Math.round(goalValue * distributionFactor),
          actual_sale: goal.sale,
          achievement_rate: 0,
          is_past: true
        });
      }
      this.goals = monthlyValues;
    },
    calculateGoalValue() {
      let goalValue = 0;
      for (const goal of this.goals) {
        goalValue += goal.target_monthly_sale;
      }
      this.goalSetting.annualSalesTargetNewValue = goalValue;
    },
    initialCalculation() {
      let goalValue = 0;
      let monthlyValues = [];
      for (const [index, goal] of this.getGoals.entries()) {
        goalValue += goal.goal;

        monthlyValues.push({
          id: goal.month.id,
          month: index + 1,
          target_monthly_sale: goal.goal,
          actual_sale: goal.sale,
          achievement_rate: 0,
          is_past: true
        });
      }
      this.goalSetting.annualSalesTargetNewValue = goalValue;
      this.goals = monthlyValues;
    },
    goalValueChange() {
      this.calculateMonthlyValue();
    },
    monthlyValueChange() {
      this.calculateGoalValue();
    },
    ...mapMutations(["showModal"])
  }
};
</script>

<style scoped>
.reference-value-background {
  background-color: #e7e7e7 !important;
}

.goal-setting-type--select >>> svg.v-icon__component.theme--light {
  height: 9px !important;
  width: 9px !important;
}
</style>
